<template>
    <v-card
        class="d-flex flex-column"
        :class="{
            'rounded-t-lg': !$vuetify.breakpoint.mdAndUp
        }"
        color="text">
        <HeaderVariant1
            v-if="$vuetify.breakpoint.mdAndUp"
            class="flex-grow-0"
            :title="modalTitle"
            @close="$emit('close')" />
        <HeaderVariant2 v-else @close="$emit('close')" />
        <v-card-text ref="modalContainer" class="flex-grow-0 content-max-width px-4 pb-2" :style="{ color:'inherit'}">
            <component
                :is="modalContent"
                :class="{ 'pt-2': !$vuetify.breakpoint.mdAndUp }" />
        </v-card-text>
    </v-card>
</template>

<script>
    import HeaderVariant1 from '@core/shared/components/modals/layout/HeaderVariant1.vue';
    import HeaderVariant2 from '@core/shared/components/modals/layout/HeaderVariant2.vue';
    import CloseModalOnRouteChangeMixin from '@core/mixins/modals/CloseModalOnRouteChange';

    export default {
        name: 'StaticPagesModalWrapper',
        components: {
            HeaderVariant1,
            HeaderVariant2
        },
        mixins: [ CloseModalOnRouteChangeMixin ],
        props: {
            page: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                modalTitle: null,
                modalContent: null
            };
        },
        methods: {
            scrollToTopModalContainer() {
                const modalContainerRef =  this.$refs.modalContainer;

                if ( modalContainerRef ) {
                    modalContainerRef.scrollTo( {
                        top: 0,
                        behavior: 'smooth'
                    } );
                }
            }
        },
        mounted( ) {
            const routeComponent = this.$router.options.routes.find( item => item.name === this.page );
            this.modalContent = routeComponent ? routeComponent.component : '';
            this.modalTitle = this.modalContent ? this.$t( `_common:pages.${this.page}.title` ) : '';

            this.$on( 'changeModalContent', ( route ) => {
                const routeObject = typeof route === 'object' ? this.$router.options.routes.find( item => item.name === route.name ) : this.$router.options.routes.find( item => {
                    const routePath = route.includes( 'modal' ) ? route.split( '/modal/static-pages' )[1] : route;
                    return item.path === routePath;
                } );
                this.modalContent = routeObject ? routeObject.component : '';
                this.modalTitle = routeObject ? this.$t( `_common:pages.${this.page.name}.title` ) : '';

                this.scrollToTopModalContainer();
            } );
        },
        destroyed() {
            this.$off( 'changeModalContent' );
        },
    };
</script>